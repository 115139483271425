/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    img: "img",
    em: "em"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "hearing-loss-decibels-chart--how-loud-is-too-loud",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-loss-decibels-chart--how-loud-is-too-loud",
    "aria-label": "hearing loss decibels chart  how loud is too loud permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing loss decibels chart — how loud is too loud?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "From sounds as quiet as a whisper to those as thundering as a jet plane engine, we all experience “noise” as a normal part of our daily lives. But not all sounds are created equal. Some are friendly to our ears, while others can cause ", React.createElement(_components.a, {
    href: "/hearing-loss/",
    className: "c-md-a"
  }, "hearing loss"), " in a matter of minutes."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The more exposure you have to unsafe levels of noise throughout your life, the more likely you are to experience hearing loss as you age. So, how do you know if a noise level is unsafe? It’s really all about the decibels."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-is-a-decibel",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-a-decibel",
    "aria-label": "what is a decibel permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is a decibel"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A decibel (dB) is the measurement for sound. The decibel scale shows a range of sounds and the corresponding decibels. Knowing what decibel level is harmful can help you protect your hearing each and every day."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "safe-and-unsafe-decibel-levels",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#safe-and-unsafe-decibel-levels",
    "aria-label": "safe and unsafe decibel levels permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Safe and unsafe decibel levels"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Safe decibel levels are those that fall at or below 70 dB. That includes things like whispers (30 dB), refrigerators (40 dB), and normal conversation (60 dB)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unsafe decibel levels of sound are those that measure above 70dB. Noise-induced hearing loss can occur overtime from frequent exposure to loud noises, including common tools and products like landscaping equipment (75 dB), food processors (95 dB), and motorcycles (100 dB). And sounds at or over 120 dB (think fireworks and jackhammers) can do some serious damage, quickly."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This decibel chart shows the normal sounds you may experience every day, along with their dB levels."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/Noise-Level-Chart.png",
    alt: "Noise Levels Chart",
    className: "c-md-img"
  }), "\n", React.createElement(_components.em, null, "Range of noise levels.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-measure-decibels",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-measure-decibels",
    "aria-label": "how to measure decibels permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to measure decibels"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Decibels can be measured using a sound level meter, which you can now find as a smartphone app. Using this app from CDC’s National Institute for Occupational Safety and Health, you can measure the sounds around you to determine if they’re safe or if your hearing is at risk. Remember, anything above 70 dB can cause damage."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you are exposed to loud noise, there are ways to protect your ears. First, put distance between yourself and the noise. Using ear plugs, noise cancelling headphones, or other ear protection can help protect your ears, too, especially if you work in or find yourself around noisy environments."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "seek-early-treatment-for-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#seek-early-treatment-for-hearing-loss",
    "aria-label": "seek early treatment for hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Seek early treatment for hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you think you have hearing loss, It’s important to have your hearing checked as soon as you can. Treating hearing loss early may stop it from getting worse and can help you protect the hearing you have left. It will also keep you in the conversation and active for years to come."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-hearcom",
    "aria-label": "why hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "Hear.com"), " is one of the top names in hearing care, known for putting the customer first. With more than 100,000 happy customers across the U.S., we’re honored to be the No. 1 choice for ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "modern hearing aids"), ". With us, you’ll receive nothing less than best–in-class care, top hearing professionals, and the most modern technology to ensure you hear your best, always."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-a-hearing-expert",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-a-hearing-expert",
    "aria-label": "contact a hearing expert permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact a hearing expert"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Reach out to hear.com for guidance and a referral to one of our top hearing specialists today. A ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing test"), " can help determine if you have mild, moderate, severe, or profound loss and allow the hearing care specialist to recommend the right hearing aids for your individual needs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Right now, you can try out some of our best hearing aids at no risk for 45 days to see how they improve your everyday life. Don’t delay. Join now to start protecting your hearing today — and for tomorrow."), "\n", React.createElement(LandingPageCta, {
    copy: "Contact a Hearing Specialist",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
